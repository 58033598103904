import React from 'react'
import Layout from '../components/Layout'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { SRLWrapper } from 'simple-react-lightbox'
import MarkdownViewer from '../components/MarkdownViewer/MarkdownViewer'
import PageHeader from '../components/PageHeader/PageHeader'
import Div from '../components/Motion/Div/Div'

export const AboutPageTemplate = ({ content, header }) => {
  return (
    <main className="bg-dark">
      <PageHeader
        title={header.title}
        imageSrc={header.backgroundImage.childImageSharp.fluid.src}
      />
      <Div className="max-w-screen-lg mx-auto py-16 px-8 md:px-16">
        <SRLWrapper>
          <MarkdownViewer content={content} />
        </SRLWrapper>
      </Div>
    </main>
  )
}

const AboutPage = ({ data: { page } }) => {
  const { header } = page.frontmatter
  return (
    <Layout>
      <Helmet>
        <title>Nossa História</title>
      </Helmet>
      {/* <AboutPageTemplate content={page.html} header={header} /> */}
    </Layout>
  )
}

export default AboutPage

export const pageQuery = graphql`
  query AboutPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        template
        title
        header {
          title
          backgroundImage {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
