import React from 'react'

const PageHeader = ({ imageSrc, title }) => {
  return (
    <div
      className="relative banner w-auto md:w-full flex flex-col justify-center pt-56 pb-16 text-center"
      style={{
        background: `linear-gradient(180deg,rgba(0,0,0,1) 0,rgba(0,0,0,.4)) ,url(${imageSrc})`
      }}
    >
      <h1 className="uppercase text-2xl text-white">{title}</h1>
    </div>
  )
}

export default PageHeader
