import React from 'react'
import Markdown from 'markdown-to-jsx'

const MarkdownViewer = ({ content }) => {
  return (
    <>
      <Markdown
        options={{
          overrides: {
            p: {
              props: {
                className:
                  'text-base font-thin text-justify leading-8 text-white my-2'
              }
            },
            h4: {
              props: {
                className: 'text-xl my-5 text-white font-bold'
              }
            },
            a: {
              props: {
                className: 'text-blue-700 underline cursor-pointer'
              }
            },
            span: {
              props: {
                className: 'mt-10'
              }
            },
            img: {
              props: {
                className: 'cursor-pointer'
              }
            }
          }
        }}
      >
        {content}
      </Markdown>
    </>
  )
}

export default MarkdownViewer
